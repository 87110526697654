<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" icon="folder-open" />
    <menu-options-item close label="Editar" @click="$emit('edit')" icon="edit" />
    <menu-options-item close label="Excluir" @click="$emit('excluir')" icon="times" label-class="text-negative" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'local-list-menu',
  props: ['local'],
  components: {MenuOptionsItem}
}
</script>
