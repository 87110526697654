export default {
    title: '',
    menu: [
        {
            id: 'checklist-acessorios',
            title: 'Acessórios',
            icon: 'car',
            style: 'regular',
            url: '/#/remocao/checklist-acessorios'
        },
        {
            id: 'checklist-campos',
            title: 'Campos',
            icon: 'check-square',
            style: 'regular',
            url: '/#/remocao/checklist-campos'
        }
    ]
}